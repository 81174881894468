<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('research_manage.monitoring_team') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
            <b-col lg="6" md="6" sm="12" xs="12">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('globalTrans.fiscal_year')"
                    label-for="fiscal_year_id"
                >
                    <b-form-select
                    plain
                    id="fiscal_year_id"
                    :options="fiscalYearList"
                    v-model="search.fiscal_year_id"
                    placeholder=""
                    >
                    <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
             <b-col lg="6" md="6" sm="12" xs="12">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('globalTrans.organization')"
                    label-for="org_id"
                >
                    <b-form-select
                    plain
                    id="org_id"
                    :options="orgList"
                    v-model="search.org_id"
                    placeholder=""
                    >
                    <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col lg="6" md="6" sm="12" xs="12">
               <b-form-group
                  class="row"
                  label-cols-sm="3"
                  :label="$t('research_manage.team_name')"
                  label-for="team_name"
                >
              <b-form-input
                id="team_name"
                v-model="search.team_name"
                placeholder=""
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('research_manage.monitoring_team') }} {{ $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="bg-primary" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                     <template v-slot:cell(org_bn)="data">
                      <span v-if="$store.state" class="capitalize">{{  $store.state.commonObj.organizationProfileList.find(e=>e.value===data.item.org_id).text_bn }}</span>
                    </template>
                     <template v-slot:cell(org)="data">
                      <span v-if="$store.state" class="capitalize">{{  $store.state.commonObj.organizationProfileList.find(e=>e.value===data.item.org_id).text }}</span>
                    </template>
                     <template v-slot:cell(fis_bn)="data">
                      <span v-if="$store.state" class="capitalize">{{  $store.state.commonObj.fiscalYearList.find(e=>e.value===data.item.fiscal_year_id).text_bn }}</span>
                    </template>
                     <template v-slot:cell(fis)="data">
                      <span v-if="$store.state" class="capitalize">{{  $store.state.commonObj.fiscalYearList.find(e=>e.value===data.item.fiscal_year_id).text }}</span>
                    </template>
                    <template v-slot:cell(status)="data">
                        <span class="badge badge-success" v-if="data.item.status === 1">{{$t('globalTrans.active')}}</span>
                        <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                        <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 border" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                        <b-button :variant="data.item.status === 1 ? ' iq-bg-success border' : '  iq-bg-danger border'" size="sm" @click="remove(data.item)">
                            <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                        </b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import FormV from './Form'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { monitoringTeamList, monitoringTeamToggleStatus } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  name: 'UiDataTable',
  mixins: [ModalBaseMasterList],
  components: {
    FormV
  },
  data () {
    return {
      search: {
        team_name: '',
        org_id: 0,
        fiscal_year_id: 0
      }
    }
  },
  computed: {
        orgList: function () {
            return this.$store.state.commonObj.organizationProfileList.map(item => {
                    return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
           })
        },
        fiscalYearList: function () {
            return this.$store.state.commonObj.fiscalYearList.map(item => {
                    return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
           })
        },
        // fiscalYearList: function () {
        //     return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        // },
         formTitle () {
          return (this.editItemId === 0) ? this.$t('research_manage.monitoring_team') + ' ' + this.$t('globalTrans.entry') : this.$t('research_manage.monitoring_team') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('globalTrans.fiscal_year'), class: 'text-left' },
          { label: this.$t('globalTrans.organization'), class: 'text-left' },
          { label: this.$t('research_manage.team_name'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fis_bn' },
          { key: 'org_bn' },
          { key: 'team_name_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fis' },
          { key: 'org' },
          { key: 'team_name' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
  //
  },
  mounted () {
    core.index()
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    remove (item) {
      this.changeStatus(agriResearchServiceBaseUrl, monitoringTeamToggleStatus, item, 'agri_research', 'monitoringTeamList')
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(agriResearchServiceBaseUrl, monitoringTeamList, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', response.data.data)
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    }
  }
}
</script>
